import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Badge, Button, Checkbox, Select } from "nlib/ui";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { checkTasksFetching, getTasksData } from "selectors/tasks";
import { getTextsData } from "selectors/texts";
import { marked } from "marked";
import { useDispatch, useSelector } from "react-redux";
import Activity from "nlib/common/Activity";
import Attachments from "nlib/common/Attachments";
import CommentsActions from "actions/CommentsActions";
import CustomDropArea from "nlib/common/CustomDropArea";
import DataConstants from "const/DataConstants";
import FormLabel from "../FormLabel";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "../SideBar";
import TaskRepeatOptions from "nlib/common/TaskRepeatOptions";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import purify from "dompurify";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useTaskContent from "hooks/useTaskContent";

const {
  COMMENT_TARGET_TYPES: { TASKS },
  COMMENT_TYPES: { COMMENT },
  TASK_STATUSES
} = DataConstants;

const END_TYPES = {
  DATE: "date",
  OCCURRENCES: "occurrences"
};

const TaskContent = ({ className, taskId, mobile }) => {
  const [, setEnvVars] = useEnvVars();

  const textContainerRef = useRef();

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const tasksFetching = useSelector(checkTasksFetching);

  const tasksData = useSelector(getTasksData);

  const businessUser = useSelector(checkIsBusinessUser);

  const [showAllAttachments, setShowAllAttachments] = useState(false);

  const [showTextExpander, setShowTextExpander] = useState(false);

  const [showAllText, setShowAllText] = useState(false);

  const [activityLoaded, setActivityLoaded] = useState(!taskId);

  const [repeatableData, setRepeatableData] = useState();

  const content = Utils.arrayFindById(tasksData, taskId);

  const { status } = content || {};

  const {
    completed,
    dueDateContent,
    dueDateStatus,
    createdByContent,
    createdAtContent,
    startDateContent,
    taskForContent
  } = useTaskContent(content || {});

  const { id: userId, role: userRole } = useSelector(getUserData);

  const canBeManaged = useMemo(() => {
    return (userRole === UserRoles.ACCOUNTANT_ADMIN.roleId || (content?.createdBy && content.createdBy.id === userId));
  }, [content?.createdBy, userRole, userId]);

  const statusOptions = useMemo(() => {
    return [
      { value: TASK_STATUSES.TO_DO, label: uiTexts[DataConstants.TASK_STATUSES_TO_UI_TEXT[TASK_STATUSES.TO_DO]] },
      { value: TASK_STATUSES.IN_PROGRESS, label: uiTexts[DataConstants.TASK_STATUSES_TO_UI_TEXT[TASK_STATUSES.IN_PROGRESS]] },
      { value: TASK_STATUSES.ON_HOLD, label: uiTexts[DataConstants.TASK_STATUSES_TO_UI_TEXT[TASK_STATUSES.ON_HOLD]] },
      { value: TASK_STATUSES.COMPLETED, label: uiTexts[DataConstants.TASK_STATUSES_TO_UI_TEXT[TASK_STATUSES.COMPLETED]] }
    ];
  }, [uiTexts]);

  const repeatOptionsHasChanges = useMemo(() => {
    const { id: prevId, ...prevData } = content?.repeatableData || {};

    const { id: nextId, ...nextData } = repeatableData || {};

    return !Utils.deepEqual(prevData, nextData);
  }, [content?.repeatableData, repeatableData]);

  const onClose = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  const editTask = useCallback(async(payload) => {
    const response = await dispatch(TasksActions.editTask(taskId, payload, true));

    if (!response) return null;

    if (payload.files?.length) {
      const comment = `${messages.filesAttached}: ${payload.files.map(({ name }) => name).join(", ")}`;

      await dispatch(
        CommentsActions.addNewComment(COMMENT, comment, [], false, response.id, TASKS)
      );
    }

    dispatch(TasksActions.fetchTasksList(false, true));

    return response;
  }, [taskId, messages, dispatch]);

  const fetchData = useCallback(async() => {
    setActivityLoaded(false);

    const [result] = await Promise.all([
      dispatch(TasksActions.fetchTask(taskId)),
      dispatch(CommentsActions.fetchComments(TASKS, taskId, true))
    ]);

    if (!result) onClose();

    setRepeatableData(result?.repeatableData);
    setActivityLoaded(true);
  }, [dispatch, taskId, onClose]);

  const handleBackClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleStatusChange = useCallback(async(newStatus) => {
    if (status === newStatus) return;

    const result = await editTask({ status: newStatus });

    if (result) {
      const message = Utils.replaceTextVars(messages.taskStatusChangedTo, { status: uiTexts[newStatus] });

      dispatch(CommentsActions.addNewComment(COMMENT, message, [], false, taskId, TASKS));
    }
  }, [status, dispatch, editTask, messages, taskId, uiTexts]);

  const handleFilesChange = useCallback(async(files) => {
    setShowAllAttachments(true);

    await editTask({ files });
  }, [editTask]);

  const handleCompletedButtonClick = useCallback(() => {
    handleStatusChange(completed ? TASK_STATUSES.TO_DO : TASK_STATUSES.COMPLETED);
  }, [handleStatusChange, completed]);

  const handleRepeatOptionsChange = useCallback((data) => {
    if (!data.enabled && content?.repeatableData?.enabled) {
      editTask({ repeatableData: data });
    }
    setRepeatableData(data);
  }, [editTask, content?.repeatableData?.enabled]);

  const handleRepeatOptionsCancel = useCallback(() => {
    setRepeatableData(content?.repeatableData);
  }, [content?.repeatableData]);

  const handleRepeatOptionsSave = useCallback(async() => {
    const response = await editTask({ repeatableData });

    if (response) setRepeatableData(response?.repeatableData);
  }, [editTask, repeatableData]);

  const handleTextExpanderClick = useCallback(() => {
    setShowAllText((prev) => !prev);
  }, []);

  const handleDeleteTask = useCallback(async() => {
    const result = await showCommonModal({ confirm: true, text: messages.taskDeleteConfirm });

    if (result) {
      setEnvVars({ editTask: null });

      await dispatch(TasksActions.deleteTask(taskId));
    }
  }, [showCommonModal, messages.taskDeleteConfirm, dispatch, taskId, setEnvVars]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [taskId, fetchData, dispatch]);

  useEffect(() => {
    if (textContainerRef.current) {
      setShowTextExpander(textContainerRef.current.clientHeight < textContainerRef.current.scrollHeight);
    }
  }, [content, activityLoaded]);

  if (!content || !activityLoaded) {
    return (
      <SideBar className={classNames(Css.taskContent, className)}>
        <SideBarHeader onCloseClick={handleBackClick}>{uiTexts.task}</SideBarHeader>
        <SideBarContent><Preloader /></SideBarContent>
      </SideBar>
    );
  }

  return (
    <SideBar className={classNames(Css.taskContent, className)}>
      <SideBarHeader onCloseClick={handleBackClick}>{uiTexts.task}</SideBarHeader>
      <SideBarContent>
        <div className={Css.contentWrap}>
          <div className={Css.col}>
            {businessUser
              ? (
                <Button
                  large block outline
                  disabled={tasksFetching}
                  className={Css.completeButton}
                  onClick={handleCompletedButtonClick}>
                  <Checkbox toggle checked={completed} />
                  <span>{completed ? uiTexts.unmarkAsComplete : uiTexts.markAsComplete}</span>
                </Button>
              )
              : (
                <Select
                  className={Css.statusSelect}
                  dropDownClassName={Css.dropDownClassName}
                  value={status}
                  options={statusOptions}
                  onChange={handleStatusChange} />
              )}
            <FormLabel>{uiTexts.fullDescription}</FormLabel>
            <div className={classNames(Css.text, showAllText && Css.showAllText)} ref={textContainerRef}>
              <div
                className={Css.markdown}
                dangerouslySetInnerHTML={{ __html: purify.sanitize(marked(content.text || "")) }} />
            </div>
            {showTextExpander && (
              <div className={Css.showMoreText} onClick={handleTextExpanderClick}>
                <div>{showAllText ? uiTexts.showLess : uiTexts.showMore}</div>
              </div>
            )}

            <div className={Css.header}>
              <div className={Css.info}>
                {!!startDateContent && (
                  <div className={Css.infoItem}>
                    <div className={Css.infoItemTitle}>{uiTexts.startDate}</div>
                    <div className={Css.infoItemValue} title={startDateContent}>
                      <Badge>{startDateContent}</Badge>
                    </div>
                  </div>
                )}

                {dueDateContent && (
                  <div className={Css.infoItem}>
                    <div className={Css.infoItemTitle}>{uiTexts.deadline}</div>
                    <div className={Css.infoItemValue}>
                      <Badge theme={dueDateStatus}>{dueDateContent}</Badge>
                    </div>
                  </div>
                )}

                <div className={Css.infoItem}>
                  <div className={Css.infoItemTitle}>{uiTexts.taskFor}</div>
                  <div className={Css.infoItemValue} title={taskForContent}>
                    <Icons.User />
                    <span>{taskForContent}</span>
                  </div>
                </div>

                <div className={Css.infoItem}>
                  <div className={Css.infoItemTitle}>{uiTexts.created}</div>
                  <div className={Css.infoItemValue}>
                    <span>{createdAtContent}</span>
                  </div>
                </div>

                {createdByContent && (
                  <div className={Css.infoItem}>
                    <div className={Css.infoItemTitle}>{uiTexts.createdBy}</div>
                    <div className={Css.infoItemValue}>
                      <span>{createdByContent}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!businessUser && (
              <div className={Css.repeatOptions}>
                <TaskRepeatOptions
                  disabled={tasksFetching}
                  startDate={content.startDate}
                  value={repeatableData}
                  onChange={handleRepeatOptionsChange} />
                {repeatableData?.enabled && repeatOptionsHasChanges && (
                  <div className={Css.actions}>
                    <Button
                      outline
                      disabled={tasksFetching}
                      onClick={handleRepeatOptionsCancel}>{uiTexts.cancel}</Button>
                    <Button
                      primary
                      disabled={tasksFetching || (
                        repeatableData.endType === END_TYPES.OCCURRENCES
                          ? !repeatableData.endAfterRepetitions
                          : !repeatableData.endAfterDate
                      )}
                      onClick={handleRepeatOptionsSave}>
                      {uiTexts.save}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <FormLabel>{uiTexts.attachedFiles}</FormLabel>
            {!!content.attachments?.length && (
              <Attachments
                className={Css.attachments}
                showAll={showAllAttachments || undefined}
                attachments={content.attachments} />
            )}
            <CustomDropArea
              disabled={tasksFetching}
              onChange={handleFilesChange} />
          </div>
          <div className={Css.col}>
            <FormLabel>{uiTexts.activity}</FormLabel>
            <Activity
              mobile={mobile}
              dataLoadedInitial
              disabled={tasksFetching}
              className={Css.activity}
              type={TASKS}
              itemId={taskId} />
          </div>
        </div>
      </SideBarContent>
      {canBeManaged && (
        <SideBarFooter>
          <Button
            danger outline large
            icon={Icons.TrashSimple}
            onClick={handleDeleteTask}>{uiTexts.deleteTask}</Button>
        </SideBarFooter>
      )}
    </SideBar>
  );
};

export default React.memo(TaskContent);
