import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useEffect, useRef } from "react";
import classNames from "classnames";

const STEP_FIRST = 0;

const STEP_SECOND = 1;

const STEP_THIRD = 2;

const STEP_FOURTH = 3;

const STEP_FIFTH = 4;

const STEP_SIXTH = 5;

const STEP_SEVENTH = 6;

const STEP_EIGHTH = 7;

const ONBOARDING_STEPS = {
  STEP_FIRST,
  STEP_SECOND,
  STEP_THIRD,
  STEP_FOURTH,
  STEP_FIFTH,
  STEP_SIXTH,
  STEP_SEVENTH,
  STEP_EIGHTH
};

const Step = ({ active, success, error, number, className, children }) => {
  const rootRef = useRef();

  useEffect(() => {
    if (active && rootRef.current.scrollIntoView) {
      rootRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [active]);

  return (
    <div
      ref={rootRef}
      data-active={active || undefined}
      data-success={success || undefined}
      data-error={error || undefined}
      className={classNames(Css.step, className, {
        [Css.active]: active,
        [Css.success]: success,
        [Css.error]: error
      })}>
      <div className={Css.number}>
        {success ? <Icons.Check weight="bold" /> : (number || <Icons.Info />)}
      </div>
      <div className={Css.line} />
      <div className={Css.content}>
        {children}
      </div>
    </div>
  );
};

export { ONBOARDING_STEPS };

export default React.memo(Step);
