import Css from "./style.module.scss";

import { getActiveTasksData, getTasksData } from "selectors/tasks";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import React from "react";
import TaskContent from "nlib/common/TaskContent";
import TaskForm from "nlib/common/TaskForm";
import Utils from "utils/Utils";
import useEnvVars from "hooks/useEnvVars";

const SidePanel = () => {
  const [{ editTask }] = useEnvVars();

  const tasksData = useSelector(getTasksData);

  const activeTasksData = useSelector(getActiveTasksData);

  const [editTaskId] = editTask ? editTask.split(".") : [];

  if (editTaskId === Constants.NEW_ENTITY_ID) {
    return (
      <TaskForm className={Css.sidePanel} />
    );
  }

  if (Utils.arrayFindById(tasksData, editTaskId) || Utils.arrayFindById(activeTasksData, editTaskId)) {
    return (
      <TaskContent className={Css.sidePanel} taskId={editTaskId} />
    );
  }

  return null;
};

export default React.memo(SidePanel);
