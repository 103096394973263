import Css from "./style.module.scss";

import Avatar from "nlib/ui/Avatar";
import React from "react";
import classNames from "classnames";

const ActivityContainer = (props) => {
  const {
    className,
    infoContent,
    children,
    avatarTitle,
    avatarUrl,
    avatar = (avatarTitle || avatarUrl) ? <Avatar className={Css.avatar} src={avatarUrl} title={avatarTitle} /> : null
  } = props;

  return (
    <div className={classNames(Css.activity, className)}>
      <div className={Css.head}>
        {!!avatar && <div className={Css.avatarWrap}>{avatar}</div>}
        <div className={Css.info}>{infoContent}</div>
      </div>
      {children && (
        <div className={Css.content}>{children}</div>
      )}
    </div>
  );
};

export default React.memo(ActivityContainer);
