import Css from "./style.module.scss";

import { Checkbox, Input, Select } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import DatePickerInput from "../DatePickerInput";
import Explanation from "./lib/Explanation";
import FormLabel from "../FormLabel";
import Radio from "nlib/ui/Radio";
import React, { useCallback, useEffect, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { TASK_REPEAT_PERIODS, TASK_REPEAT_END_TYPES } = DataConstants;

const MAX_HOURS = 24;

const MAX_DAYS = 31;

const MAX_WEEKS = 4;

const MAX_MONTHS = 12;

const MAX_YEARS = 10;

const MAX_VALUES = {
  [TASK_REPEAT_PERIODS.HOUR]: MAX_HOURS,
  [TASK_REPEAT_PERIODS.DAY]: MAX_DAYS,
  [TASK_REPEAT_PERIODS.WEEK]: MAX_WEEKS,
  [TASK_REPEAT_PERIODS.MONTH]: MAX_MONTHS,
  [TASK_REPEAT_PERIODS.YEAR]: MAX_YEARS
};

const DEFAULT_VALUES = {
  enabled: false,
  periodType: TASK_REPEAT_PERIODS.WEEK,
  periodValue: 1,
  endType: TASK_REPEAT_END_TYPES.DATE,
  duePeriodType: TASK_REPEAT_PERIODS.WEEK,
  duePeriodValue: 0
};

const TaskRepeatOptions = ({ disabled, className, value = DEFAULT_VALUES, startDate, onChange }) => {
  const {
    enabled,
    periodValue,
    periodType,
    endType,
    endAfterDate,
    endAfterRepetitions,
    occurrencesRemaining,
    duePeriodType,
    duePeriodValue
  } = value;

  const { uiTexts } = useSelector(getTextsData);

  const periodMultiplierOptions = useMemo(() => {
    return Array.from({ length: MAX_VALUES[periodType] }, (item, index) => {
      const number = index + 1;

      return { value: number, label: number };
    });
  }, [periodType]);

  const dueInMultiplierOptions = useMemo(() => {
    return [
      { value: 0, label: uiTexts.none },
      ...Array.from({ length: MAX_VALUES[duePeriodType] }, (item, index) => {
        const number = index + 1;

        return { value: number, label: number };
      })
    ];
  }, [duePeriodType, uiTexts]);

  const periodOptions = useMemo(() => {
    return [
      Utils.checkIsStagingEnv() && { value: TASK_REPEAT_PERIODS.HOUR, label: "hours" },
      { value: TASK_REPEAT_PERIODS.DAY, label: uiTexts.days },
      { value: TASK_REPEAT_PERIODS.WEEK, label: uiTexts.weeks },
      { value: TASK_REPEAT_PERIODS.MONTH, label: uiTexts.months },
      { value: TASK_REPEAT_PERIODS.YEAR, label: uiTexts.years }
    ].filter(Boolean);
  }, [uiTexts]);

  const handlePeriodValueChange = useCallback((newValue) => {
    onChange({ ...value, periodValue: newValue });
  }, [value, onChange]);

  const handlePeriodTypeChange = useCallback((newValue) => {
    onChange({ ...value, periodType: newValue });
  }, [value, onChange]);

  const handleEndsAfterDateClick = useCallback(() => {
    if (disabled) return;

    onChange({
      ...value,
      endType: TASK_REPEAT_END_TYPES.DATE,
      endAfterRepetitions: null
    });
  }, [disabled, value, onChange]);

  const handleEndsAfterOccurrencesClick = useCallback(() => {
    if (disabled) return;

    onChange({
      ...value,
      endType: TASK_REPEAT_END_TYPES.OCCURRENCES,
      endAfterDate: null
    });
  }, [disabled, value, onChange]);

  const handleEndsAfterDateChange = useCallback((newValue) => {
    onChange({
      ...value,
      endType: TASK_REPEAT_END_TYPES.DATE,
      endAfterDate: newValue ? moment.utc(newValue).toISOString() : null
    });
  }, [onChange, value]);

  const handleEndsAfterOccurrencesChange = useCallback((newValue) => {
    onChange({
      ...value,
      endType: TASK_REPEAT_END_TYPES.OCCURRENCES,
      endAfterRepetitions: newValue ? Math.abs(Math.round(newValue)) : ""
    });
  }, [onChange, value]);

  const handleEnabledChange = useCallback(() => {
    onChange({ ...value, enabled: !value.enabled });
  }, [value, onChange]);

  const handleDueDateValueChange = useCallback((newValue) => {
    onChange({ ...value, duePeriodValue: newValue });
  }, [onChange, value]);

  const handleDueDatePeriodChange = useCallback((newValue) => {
    onChange({ ...value, duePeriodType: newValue });
  }, [onChange, value]);

  useEffect(() => {
    if (periodValue > MAX_VALUES[periodType]) {
      onChange({ ...value, periodValue: MAX_VALUES[periodType] });
    }
  }, [onChange, periodType, periodValue, value]);

  useEffect(() => {
    if (duePeriodValue > MAX_VALUES[duePeriodType]) {
      onChange({ ...value, duePeriodValue: MAX_VALUES[duePeriodType] });
    }
  }, [duePeriodType, duePeriodValue, onChange, value]);

  return (
    <div className={classNames(Css.taskRepeatOptions, className)}>
      <FormLabel className={Css.toggleRow}>
        <span>{uiTexts.repeat}</span>
        <Checkbox toggle disabled={disabled} checked={enabled} onChange={handleEnabledChange} />
      </FormLabel>
      {enabled && (
        <>
          <div className={Css.row}>
            <div className={Css.col}>
              <div className={Css.label}>{uiTexts.every}</div>
            </div>
            <div className={Css.colOne}>
              <Select
                topOffset={52}
                disabled={disabled}
                value={periodValue}
                options={periodMultiplierOptions}
                onChange={handlePeriodValueChange} />
            </div>
            <div className={Css.colOne}>
              <Select
                disabled={disabled}
                value={periodType}
                options={periodOptions}
                onChange={handlePeriodTypeChange} />
            </div>
          </div>

          <div className={Css.row}>
            <div className={Css.col}>
              <div
                className={classNames(Css.label, !disabled && Css.cursorPointer)}
                onClick={handleEndsAfterDateClick}>
                <Radio
                  name="endAfterType"
                  className={Css.radio}
                  value={TASK_REPEAT_END_TYPES.DATE}
                  checked={endType === TASK_REPEAT_END_TYPES.DATE}>
                  <span>{uiTexts.endOn}</span>
                </Radio>
              </div>
            </div>
            <div className={Css.colTwo}>
              <DatePickerInput
                portal
                disabled={disabled}
                placeholder={uiTexts.enterDate}
                invalid={endType === TASK_REPEAT_END_TYPES.DATE && !endAfterDate}
                value={endAfterDate || ""}
                onChange={handleEndsAfterDateChange} />
            </div>
          </div>

          <div className={Css.row}>
            <div className={Css.col}>
              <div
                className={classNames(Css.label, !disabled && Css.cursorPointer)}
                onClick={handleEndsAfterOccurrencesClick}>
                <Radio
                  disabled={disabled}
                  name="endAfterType"
                  className={Css.radio}
                  value={TASK_REPEAT_END_TYPES.OCCURRENCES}
                  checked={endType === TASK_REPEAT_END_TYPES.OCCURRENCES}>
                  <span>{uiTexts.endsAfter}</span>
                </Radio>
              </div>
            </div>
            <div className={Css.colTwo}>
              <Input
                type="number"
                disabled={disabled}
                placeholder={uiTexts.numberOfTasks}
                invalid={endType === TASK_REPEAT_END_TYPES.OCCURRENCES && !endAfterRepetitions}
                value={endAfterRepetitions || ""}
                onChange={handleEndsAfterOccurrencesChange} />
            </div>
          </div>

          <div className={Css.row}>
            <div className={Css.col}>
              <div className={Css.label}>{uiTexts.dueIn}</div>
            </div>
            <div className={Css.colOne}>
              <Select
                disabled={disabled}
                value={duePeriodValue}
                options={dueInMultiplierOptions}
                onChange={handleDueDateValueChange} />
            </div>
            <div className={Css.colOne}>
              <Select
                disabled={disabled}
                value={duePeriodType}
                options={periodOptions}
                onChange={handleDueDatePeriodChange} />
            </div>
          </div>
          <Explanation
            className={Css.taskRepeatExplanation}
            startDate={startDate}
            periodValue={periodValue}
            periodType={periodType}
            endType={endType}
            endAfterDate={endAfterDate}
            endAfterRepetitions={endAfterRepetitions}
            occurrencesRemaining={occurrencesRemaining}
            duePeriodType={duePeriodType}
            duePeriodValue={duePeriodValue} />
        </>
      )}
    </div>
  );
};

export default React.memo(TaskRepeatOptions);
