import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkCommentsFetching, getUnreadCommentsData } from "selectors/comments";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "nlib/ui/Button";
import CommentsActions from "actions/CommentsActions";
import Constants from "const/Constants";
import Counter from "nlib/ui/Counter";
import DataConstants from "const/DataConstants";
import DropDown, { DropDownContent, DropDownMenuItem, DropDownToggle } from "nlib/ui/DropDown";
import Pages from "nlib/pages/Pages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { COMMENT_TARGET_TYPES: { TASKS, TRANSACTIONS, DOCUMENTS, ARCHIVE, AUDIT } } = DataConstants;

const MAX_TARGET_INFO_LENGTH = 20;

const TARGETS_PARAM_NAMES = {
  [TASKS]: "editTask",
  [TRANSACTIONS]: "editItem",
  [AUDIT]: "editItem",
  [ARCHIVE]: "editItem",
  [DOCUMENTS]: "editItem"
};

const COMMENT_TARGET_TYPES = {
  documents: { icon: Icons.FileText, path: "originalDocumentId" },
  transactions: {
    icon: Icons.CreditCard,
    path: "timestamp",
    convertor: (timestamp) => moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
  },
  audit: {
    icon: Icons.Flag,
    path: "timestamp",
    route: `${UiRoutes.AUDIT}/${Constants.AUDIT_SECTIONS.TRANSACTIONS}/${Constants.AUDIT_SUBSECTIONS_ROUTES.ALL_CATEGORIZED}`,
    convertor: (timestamp) => moment.utc(timestamp).format(Constants.DATETIME_FORMATS.DATE_TEXT)
  },
  archive: { icon: Icons.FolderNotch, path: "attachment.originalName" },
  tasks: { icon: Icons.CheckSquare, path: "title" }
};

const Notifications = ({ modal, disabled, className }) => {
  const history = useHistory();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const unreadCommentsData = useSelector(getUnreadCommentsData);

  const commentsFetching = useSelector(checkCommentsFetching);

  const [dropdownOpened, setDropdownOpened] = useState(false);

  const route = useMemo(() => {
    return Pages.getCurrentRouteInfo(pathname).route;
  }, [pathname]);

  const filteredUnreadCommentsData = useMemo(() => {
    return unreadCommentsData.filter(({ targetType }) => TARGETS_PARAM_NAMES[targetType]);
  }, [unreadCommentsData]);

  const commentsGroupedByTarget = useMemo(() => {
    const resultObject = filteredUnreadCommentsData.reduce((aggregator, comment) => {
      const { targetId } = comment;

      const foundItem = Utils.arrayFindById(aggregator, targetId);

      if (foundItem) {
        foundItem.comments.push(comment);
      } else {
        aggregator.push({
          id: targetId,
          comments: [comment]
        });
      }

      return aggregator;
    }, []);

    const presorted = resultObject
      .map((item) => ({
        ...item,
        comments: Utils.arraySort(item.comments, "createdAt")
      }));

    return Utils.arraySort(presorted, (item) => item.comments[0].createdAt);
  }, [filteredUnreadCommentsData]);

  const hasUnread = !!filteredUnreadCommentsData.length;

  const handleItemClick = useCallback((commentId) => {
    const { businessId, targetId, targetType } = Utils.arrayFindById(unreadCommentsData, commentId);

    const queryString = Utils.objectToQueryString({
      [TARGETS_PARAM_NAMES[targetType] || TARGETS_PARAM_NAMES[TRANSACTIONS]]: targetId
    });

    const { route: customRoute } = COMMENT_TARGET_TYPES[targetType];

    history.push(`/${businessId}${customRoute || UiRoutes[targetType.toUpperCase()] || route}?${queryString}.comments`);
  }, [route, history, unreadCommentsData]);

  const handleMarkAllReadClick = useCallback(async() => {
    setDropdownOpened(false);

    const modalResult = await showCommonModal({
      text: messages.actionConfirm,
      headerText: uiTexts.confirm,
      confirm: true
    });

    if (!modalResult) return;

    dispatch(CommentsActions.markAllAsRead());
  }, [dispatch, messages.actionConfirm, uiTexts.confirm, showCommonModal]);

  useEffect(() => {
    dispatch(CommentsActions.fetchUnreadComments());

    return Utils.setInterval(() => {
      dispatch(CommentsActions.fetchUnreadComments());
    }, Constants.DATA_LIST_UPDATE_INTERVAL);
  }, [dispatch]);

  return (
    <div className={classNames(Css.notifications, className)}>
      <DropDown disabled={!hasUnread || disabled} opened={dropdownOpened} setOpened={setDropdownOpened}>
        <DropDownToggle
          className={classNames(Css.toggle, hasUnread && Css.hasUnread)}>
          <Icons.ChatText />
          {hasUnread && (
            <Counter negative className={Css.counter}>
              {filteredUnreadCommentsData.length}
            </Counter>
          )}
        </DropDownToggle>
        <DropDownContent
          alignRight
          modal={modal}
          className={Css.dropdownContent}>
          {hasUnread && (
            <>
              <div className={Css.top}>
                <div className={Css.title}>
                  <Icons.ChatText />
                  <span>{uiTexts.unreadComments}</span>
                </div>
                <Button
                  disabled={commentsFetching}
                  onClick={handleMarkAllReadClick}>{uiTexts.markAllAsRead}</Button>
              </div>
              <div className={Css.content}>
                {commentsGroupedByTarget.map((item) => {
                  const { id, comments } = item;

                  const [comment] = comments;

                  const {
                    id: commentId,
                    text,
                    targetData,
                    targetType,
                    createdAt,
                    createdBy,
                    businessName
                  } = comment;

                  const { email } = createdBy || {};

                  const commentDate = moment.duration(moment(createdAt).diff(moment())).humanize(true);

                  const { icon: Icon, path, convertor = (value) => value } = COMMENT_TARGET_TYPES[targetType];

                  const targetInfo = convertor(Utils.getPropertyByPath(targetData || {}, path) || "");

                  const targetInfoTrimmed = targetInfo.length > MAX_TARGET_INFO_LENGTH
                    ? `${targetInfo.substr(0, MAX_TARGET_INFO_LENGTH)}...` : targetInfo;

                  const label = `${targetInfoTrimmed
                    ? `${targetInfoTrimmed} | `
                    : ""}${businessName || `(${uiTexts.deletedBusiness.toUpperCase()})`}`;

                  return (
                    <DropDownMenuItem
                      key={id}
                      value={commentId}
                      className={Css.menuItem}
                      disabled={commentsFetching || !businessName}
                      onClick={handleItemClick}>
                      <div className={Css.mainInfo}>
                        <span className={Css.title}>
                          <div className={Css.icon}><Icon /></div>
                          <div className={Css.info} title={label}>{label}</div>
                        </span>
                        <Counter negative className={Css.counter}>{comments.length}</Counter>
                      </div>
                      <div className={Css.text} title={text}>{text}</div>
                      <div className={Css.footer}>
                        {`${email || uiTexts.unknown}, ${commentDate}`}
                      </div>
                    </DropDownMenuItem>
                  );
                })}
              </div>
            </>
          )}
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default React.memo(Notifications);
