import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import objectHash from "object-hash";
import useShowCommonModal from "hooks/useShowCommonModal";

const ShowIntroButton = ({ className }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const showCommonModal = useShowCommonModal();

  const handleShowIntroButtonClick = useCallback(async() => {
    const modalResult = await showCommonModal({
      text: messages.resetIntroGuide,
      headerText: uiTexts.confirm,
      confirm: true
    });

    if (modalResult) {
      const hash = objectHash({ v: userData.email });

      dispatch(UiActions.togglePreloader(true));
      Utils.storageValue(`${Constants.LS_KEYS.EXCLUDED_INTROS}_${hash}`, null);
      window.location.replace(`${`${window.location.origin}/${selectedBusinessId}${UiRoutes.TRANSACTIONS}`}`);
    }
  }, [dispatch, messages.resetIntroGuide, selectedBusinessId, showCommonModal, uiTexts.confirm, userData.email]);

  return (
    <div
      className={classNames(Css.showIntroButton, className)}
      onClick={handleShowIntroButtonClick}>
      <Icons.Question />
    </div>
  );
};

export default React.memo(ShowIntroButton);
