import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityContainer from "nlib/common/Activity/lib/ActivityContainer";
import ChangedFieldsList from "./lib/ChangedFieldsList";
import React, { useMemo } from "react";
import moment from "moment";

const UPDATE_ACTIONS = {
  ADDED: "added",
  RECOGNISE_START: "recogniseStart",
  RECOGNISE_DONE: "recogniseDone",
  RECOGNISE_ERROR: "recogniseError",
  RECOGNISE_DUPLICATE: "recogniseDuplicate",
  STATUS_CHANGE: "statusChange",
  USER_UPDATE: "userUpdate",
  SYSTEM_UPDATE: "systemUpdate",
  USER_PAIRING: "userPairing",
  USER_UNPAIRING: "userUnpairing",
  SYSTEM_PAIRING: "systemPairing",
  SYSTEM_UNPAIRING: "systemUnpairing"
};

const {
  ADDED,
  RECOGNISE_START,
  RECOGNISE_DONE,
  RECOGNISE_ERROR,
  RECOGNISE_DUPLICATE,
  STATUS_CHANGE,
  USER_UPDATE,
  SYSTEM_UPDATE,
  USER_PAIRING,
  USER_UNPAIRING,
  SYSTEM_PAIRING,
  SYSTEM_UNPAIRING
} = UPDATE_ACTIONS;

const Event = ({ user, createdAt, action, updates, prevRevDocument, currentRevDocument }) => {
  const { uiTexts, documentUpdateActions: actionsTexts } = useSelector(getTextsData);

  const actionParamaters = useMemo(
    () => ({
      [ADDED]: {
        icon: Icons.PlusCircle,
        iconClassName: Css.dark,
        label: actionsTexts.added
      },
      [RECOGNISE_START]: {
        icon: Icons.Cloud,
        iconClassName: Css.warning,
        label: actionsTexts.recogniseStart,
        systemEvent: true
      },
      [RECOGNISE_DONE]: {
        icon: Icons.FileText,
        iconClassName: Css.positive,
        label: actionsTexts.recogniseDone,
        systemEvent: true
      },
      [RECOGNISE_ERROR]: {
        icon: Icons.EyeClosed,
        iconClassName: Css.negative,
        label: actionsTexts.recogniseError,
        systemEvent: true
      },
      [RECOGNISE_DUPLICATE]: {
        icon: Icons.Copy,
        iconClassName: Css.warning,
        label: actionsTexts.recogniseDuplicate,
        systemEvent: true
      },
      [STATUS_CHANGE]: {
        icon: Icons.ArrowRight,
        iconClassName: Css.dark,
        label: actionsTexts.statusChange
      },
      [USER_UPDATE]: {
        icon: Icons.PencilSimple,
        iconClassName: Css.highlight,
        label: actionsTexts.userUpdate
      },
      [SYSTEM_UPDATE]: {
        icon: Icons.ArrowsClockwise,
        iconClassName: Css.highlight,
        label: actionsTexts.systemUpdate,
        systemEvent: true
      },
      [USER_PAIRING]: {
        icon: Icons.Link,
        iconClassName: Css.special,
        label: actionsTexts.userPairing
      },
      [USER_UNPAIRING]: {
        icon: Icons.CornersOut,
        iconClassName: Css.negative,
        label: actionsTexts.userUnpairing
      },
      [SYSTEM_PAIRING]: {
        icon: Icons.Link,
        iconClassName: Css.special,
        label: actionsTexts.systemPairing,
        systemEvent: true
      },
      [SYSTEM_UNPAIRING]: {
        icon: Icons.CornersOut,
        iconClassName: Css.negative,
        label: actionsTexts.systemUnpairing,
        systemEvent: true
      }
    }), [actionsTexts]
  );

  const {
    icon: Icon,
    label: actionLabel,
    iconClassName,
    systemEvent
  } = actionParamaters[action] || actionParamaters[SYSTEM_UPDATE];

  const eventDate = useMemo(() => {
    return moment.duration(moment(createdAt).diff(moment())).humanize(true);
  }, [createdAt]);

  const userFullName = useMemo(() => {
    const { fullName, email } = user || {};

    return fullName ? fullName + (fullName === email ? "" : ` <${email}>`) : email || uiTexts.unknown.toLowerCase();
  }, [user, uiTexts]);

  const avatar = useMemo(() => {
    return (<div className={`${Css.icon} ${iconClassName}`}><Icon /></div>);
  }, [iconClassName]);

  const infoContent = useMemo(() => {
    return (
      <div className={Css.info}>
        <div className={Css.title}>{actionLabel}</div>
        <div className={Css.meta}>
          {systemEvent ? <Icons.Gear /> : <Icons.User />}
          <span>{`${systemEvent ? uiTexts.system.toUpperCase() : (userFullName)}, `}</span>
          <span className={Css.date}>{eventDate}</span>
        </div>
      </div>
    );
  }, [actionLabel, systemEvent, uiTexts, userFullName, eventDate]);

  return (
    <ActivityContainer
      className={Css.event}
      avatar={avatar}
      infoContent={infoContent}>
      <div className={`${Css.contentWrapper} ${iconClassName}`}>
        <ChangedFieldsList
          updates={updates}
          prevRevDocument={prevRevDocument}
          currentRevDocument={currentRevDocument} />
      </div>
    </ActivityContainer>
  );
};

export default React.memo(Event);
