import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import React, { useCallback, useState } from "react";
import RpaFrequencies from "const/RpaFrequencies";
import Step from "../Step";

const SelectFrequencyStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const [fetching, setFetching] = useState(false);

  const handleFrequencyButtonClick = useCallback(async({ currentTarget: { dataset: { value } } }) => {
    setFetching(true);

    await dispatch(
      BusinessesActions.editBusinessSettings(selectedBusinessId, { rpaFrequency: value }, true)
    );

    setFetching(false);
  }, [selectedBusinessId, dispatch]);

  return (
    <Step
      number={index + 1}
      className={ParentCss.stepThird}
      active={index === currentStep}
      success={currentStep > index}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>
            {messages.onboardingSelectFrequency}
          </div>
          {currentStep === index && (
            <div className={ParentCss.buttons}>
              <Button
                outline large
                loading={fetching}
                data-value={RpaFrequencies.DAILY}
                className={ParentCss.button}
                onClick={handleFrequencyButtonClick}>
                <span>{`${uiTexts.daily} (${messages.frequencyDaily.toLowerCase()})`}</span>
              </Button>
              <Button
                outline large
                loading={fetching}
                data-value={RpaFrequencies.WEEKLY}
                className={ParentCss.button}
                onClick={handleFrequencyButtonClick}>
                <span>{`${uiTexts.weekly} (${messages.frequencyWeekly.toLowerCase()})`}</span>
              </Button>
              <Button
                outline large
                loading={fetching}
                data-value={RpaFrequencies.MONTHLY}
                className={ParentCss.button}
                onClick={handleFrequencyButtonClick}>
                <span>{`${uiTexts.monthly} (${messages.frequencyMonthly.toLowerCase()})`}</span>
              </Button>
            </div>
          )}
        </div>
        {currentStep > index && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.selected}</span>
          </div>
        )}
      </div>
    </Step>
  );
};

export default React.memo(SelectFrequencyStep);
