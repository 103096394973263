import ParentCss from "../../style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox, TagsInput, Tooltip } from "nlib/ui";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getCurrentQuickBooksRealmId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DatePickerInput from "nlib/common/DatePickerInput";
import React, { useCallback, useMemo, useState } from "react";
import RpaTransactionTypes from "const/RpaTransactionTypes";
import Step from "../Step";
import Utils from "utils/Utils";
import moment from "moment";

const { DATETIME_FORMATS } = Constants;

const RpaSettingsStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const quickBooksBusiness = useSelector(getCurrentQuickBooksRealmId);

  const { appsumoValue } = useSelector(getActiveOrganization);

  const {
    id: selectedBusinessId,
    extraData: { quickBooksBookCloseDate } = {}
  } = useSelector(getSelectedBusinessData);

  const accountsData = useSelector(getAccountsData);

  const accountNames = useMemo(() => {
    return accountsData
      .filter(({ extraData: { paymentAccount } = {} }) => !quickBooksBusiness || paymentAccount)
      .map(({ name }) => name.trim().replace(/\s+/g, " "));
  }, [quickBooksBusiness, accountsData]);

  const accountNameOptions = useMemo(() => {
    return accountNames.map((name) => ({ value: name, label: name }));
  }, [accountNames]);

  const [fetching, setFetching] = useState(false);

  const [rpaAutoApprove, setRpaAutoApprove] = useState(true);

  const [rpaExcludedAccountsToggle, setRpaExcludedAccountsToggle] = useState(true);

  const [allowAutoCreatePayee, setAllowAutoCreatePayee] = useState(true);

  const [allowApproveWithoutPayee, setAllowApproveWithoutPayee] = useState(true);

  const [rpaExcludedAccounts, setRpaExcludedAccounts] = useState([]);

  const [rpaTransactionTypes, setRpaTransactionTypes] = useState(RpaTransactionTypes.BOTH);

  const minDate = useMemo(() => {
    return moment().subtract(1, "years").startOf("year");
  }, []);

  const [rpaStartFrom, setRpaStartFrom] = useState(() => {
    return quickBooksBookCloseDate
      ? moment.utc(quickBooksBookCloseDate).add(1, "days").format(DATETIME_FORMATS.API_DATE)
      : minDate.format(DATETIME_FORMATS.API_DATE);
  });

  const dateError = moment(rpaStartFrom).isBefore(minDate);

  const [
    titleText,
    descriptionText,
    excludeAccountsPlaceholderText,
    appsumoExtraText
  ] = messages.onboardingRpaSettings;

  const handleRpaAutoApproveToggleChange = useCallback((value) => {
    setRpaAutoApprove(value);
  }, []);

  const handleRpaExcludedAccountsToggleChange = useCallback((value) => {
    setRpaExcludedAccountsToggle(value);
    setRpaExcludedAccounts([]);
  }, []);

  const handleAllowApproveWithoutPayeeToggleChange = useCallback((value) => {
    setAllowApproveWithoutPayee(value);
  }, []);

  const handleAllowAutoCreatePayeeToggleChange = useCallback((value) => {
    setAllowAutoCreatePayee(value);
  }, []);

  const handleRpaExcludedAccountsInputChange = useCallback((value) => {
    setRpaExcludedAccounts(
      value.filter((item) => item && accountNames.includes(item))
    );
  }, [accountNames]);

  const handleWhoAskedButtonClick = useCallback((event, value) => {
    setRpaTransactionTypes(value);
  }, []);

  const handleRpaStartFromInputChange = useCallback((value) => {
    setRpaStartFrom(value);
  }, []);

  const handleContinueButtonClick = useCallback(async() => {
    setFetching(true);

    await dispatch(
      BusinessesActions.editBusinessSettings(
        selectedBusinessId,
        {
          rpaAutoApprove,
          rpaExcludedAccounts,
          rpaTransactionTypes,
          allowAutoCreatePayee,
          allowApproveWithoutPayee,
          rpaStartFrom: Utils.formatNoTimeZoneDate(rpaStartFrom)
        },
        true
      )
    );

    setFetching(false);
  }, [
    selectedBusinessId,
    rpaStartFrom,
    rpaAutoApprove,
    rpaExcludedAccounts,
    rpaTransactionTypes,
    allowAutoCreatePayee,
    allowApproveWithoutPayee,
    dispatch
  ]);

  return (
    <Step
      number={index + 1}
      className={Css.step}
      active={currentStep === index}
      success={currentStep > index}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>{titleText}</div>
          {currentStep === index && (
            <>
              <div className={ParentCss.text}>{descriptionText}</div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaAutoApprove}
                  label={messages.autoApproveToggleLabelText}
                  onChange={handleRpaAutoApproveToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={allowAutoCreatePayee}
                  label={messages.autoCreatePayeeToggleLabelText}
                  onChange={handleAllowAutoCreatePayeeToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={allowApproveWithoutPayee}
                  label={messages.approveWithoutPayeeToggleLabelText}
                  onChange={handleAllowApproveWithoutPayeeToggleChange} />
              </div>
              <div className={ParentCss.buttons}>
                <Checkbox
                  toggle
                  checked={rpaExcludedAccountsToggle}
                  label={messages.processAllAccountsToggleLabelText}
                  onChange={handleRpaExcludedAccountsToggleChange} />
              </div>
              {!rpaExcludedAccountsToggle && <div className={ParentCss.buttons}>
                <TagsInput
                  className={Css.tagsInput}
                  value={rpaExcludedAccounts}
                  invalid={!rpaExcludedAccounts.length}
                  autoCompleteData={accountNameOptions}
                  onChange={handleRpaExcludedAccountsInputChange}
                  placeholder={excludeAccountsPlaceholderText} />
              </div>}
              <div className={ParentCss.buttons}>
                <Button
                  outline large block
                  loading={fetching}
                  primary={rpaTransactionTypes === RpaTransactionTypes.BOTH}
                  value={RpaTransactionTypes.BOTH}
                  className={ParentCss.button}
                  onClick={handleWhoAskedButtonClick}>
                  <span>{uiTexts.incomeAndExpenses}</span>
                </Button>
                <Button
                  outline large block
                  loading={fetching}
                  primary={rpaTransactionTypes === RpaTransactionTypes.INCOME}
                  value={RpaTransactionTypes.INCOME}
                  className={ParentCss.button}
                  onClick={handleWhoAskedButtonClick}>
                  <span>{uiTexts.incomeOnly}</span>
                </Button>
                <Button
                  outline large block
                  loading={fetching}
                  primary={rpaTransactionTypes === RpaTransactionTypes.EXPENSES}
                  value={RpaTransactionTypes.EXPENSES}
                  className={ParentCss.button}
                  onClick={handleWhoAskedButtonClick}>
                  <span>{uiTexts.expensesOnly}</span>
                </Button>
              </div>
              <div className={Css.label}>{uiTexts.reviewTransactionFrom}</div>
              <div className={ParentCss.row}>
                <div className={ParentCss.col}>
                  <div className={Css.dateWrap}>
                    <Tooltip className={Css.tooltip} opened={dateError}>
                      <Icons.Warning />
                      <span>
                        {Utils.replaceTextVars(messages.rpaMinDateWarning, { date: minDate.format(DATETIME_FORMATS.DATE_TEXT) })}
                      </span>
                    </Tooltip>
                    <DatePickerInput
                      value={rpaStartFrom}
                      minDate={minDate}
                      error={!rpaStartFrom || dateError}
                      invalid={!rpaStartFrom || dateError}
                      placeholder={messages.onboardingRpaStartFromPlaceholder}
                      onChange={handleRpaStartFromInputChange} />
                  </div>
                </div>
                <Button
                  primary large block
                  loading={fetching}
                  disabled={dateError
                    || !rpaTransactionTypes
                    || !rpaStartFrom
                    || (!rpaExcludedAccountsToggle && !rpaExcludedAccounts.length)}
                  className={ParentCss.likeButton}
                  onClick={handleContinueButtonClick}>{uiTexts.continue}</Button>
              </div>
            </>
          )}
        </div>
        {currentStep > index && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.selected}</span>
          </div>
        )}
      </div>
      {currentStep === index && !!appsumoValue && (
        <div className={ParentCss.warning}>
          <Icons.WarningCircle />
          <span className={ParentCss.text}>{Utils.replaceTextVars(appsumoExtraText, { months: appsumoValue })}</span>
        </div>
      )}
    </Step>
  );
};

export default React.memo(RpaSettingsStep);
