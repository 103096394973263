import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Attachments from "nlib/common/Attachments";
import Button from "nlib/ui/Button";
import CustomDropArea from "nlib/common/CustomDropArea";
import DataConstants from "const/DataConstants";
import DatePickerInput from "nlib/common/DatePickerInput";
import FormElement from "nlib/common/Form/lib/FormElement";
import FormLabel from "nlib/common/FormLabel";
import React, { useCallback, useMemo } from "react";
import SelectTaskStatus from "nlib/common/SelectTaskStatus";
import SelectUserAutocomplete from "./lib/SelectUserAutocomplete";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import TaskRepeatOptions from "nlib/common/TaskRepeatOptions";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { TASK_REPEAT_END_TYPES } = DataConstants;

const FormContent = (props) => {
  const {
    className,
    disabled,
    values,
    attachments,
    setAttachments,
    onSubmit,
    onCancel
  } = props;

  const { text, startDate, dueDate, targetUser, repeatableData } = values;

  const hasText = !!text.trim();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const maxStartDate = useMemo(() => {
    return dueDate && moment(dueDate).startOf("day").toDate();
  }, [dueDate]);

  const minDueDate = useMemo(() => {
    return moment(startDate || undefined).startOf("day").toDate();
  }, [startDate]);

  const disableSubmit = useMemo(() => {
    return !hasText || !targetUser || !startDate || (
      repeatableData?.enabled && (
        (repeatableData.endType === TASK_REPEAT_END_TYPES.DATE
          ? !repeatableData.endAfterDate : !repeatableData.endAfterRepetitions)
        || !repeatableData.periodType
        || !repeatableData.periodValue
      )
    );
  }, [
    hasText,
    targetUser,
    startDate,
    repeatableData?.enabled,
    repeatableData?.endType,
    repeatableData?.endAfterDate,
    repeatableData?.endAfterRepetitions,
    repeatableData?.periodType,
    repeatableData?.periodValue
  ]);

  const handleCloseClick = useCallback(async() => {
    if (disabled) onCancel();
    else {
      if (hasText || dueDate || attachments.length) {
        const result = await showCommonModal({
          confirm: true,
          text: messages.closeWhenHasUnsavedChanges
        });

        if (!result) return;
      }
      onCancel();
    }
  }, [disabled, onCancel, hasText, dueDate, attachments.length, showCommonModal, messages]);

  const handleFilesChange = useCallback((newFiles) => {
    const newItems = [...newFiles].map((file) => {
      return { id: uuid(), createdAt: new Date(), originalName: file.name, file };
    });

    setAttachments((prev) => [...prev, ...newItems]);
  }, [setAttachments]);

  const handleDeleteAttachment = useCallback((id) => {
    setAttachments((prev) => prev.filter((item) => item.id !== id));
  }, [setAttachments]);

  return (
    <SideBar className={classNames(Css.formContent, className)}>
      <SideBarHeader disabled={disabled} onCloseClick={handleCloseClick}>
        {uiTexts.newTask}
      </SideBarHeader>
      <SideBarContent>
        <div className={Css.contentWrap}>
          <div className={Css.colTablet}>
            {!businessUser && (
              <FormElement
                className={Css.selectStatus}
                name="status"
                element={SelectTaskStatus} />
            )}
            <div className={Css.formRow}>
              <FormLabel>{uiTexts.text}</FormLabel>
              <FormElement
                multiline
                disabled={disabled}
                name="text"
                invalid={!hasText}
                placeholder={uiTexts.enterTaskText}
                className={Css.formElement} />
            </div>
            <div className={Css.formRow}>
              <FormLabel>{uiTexts.taskFor}</FormLabel>
              <FormElement
                disabled={disabled}
                name="targetUser"
                className={Css.formElement}
                invalid={!targetUser}
                element={SelectUserAutocomplete} />
            </div>
            <div className={Css.formRow}>
              <div className={Css.row}>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.startDate}</FormLabel>
                  <FormElement
                    portal
                    disabled={disabled}
                    name="startDate"
                    invalid={!startDate}
                    iconBefore={Icons.Calendar}
                    placeholder={uiTexts.enterDate}
                    className={Css.formElement}
                    maxDate={maxStartDate}
                    element={DatePickerInput} />
                </div>
                <div className={Css.col}>
                  <FormLabel>{uiTexts.term}</FormLabel>
                  <FormElement
                    portal
                    disabled={disabled}
                    name="dueDate"
                    iconBefore={Icons.Calendar}
                    placeholder={uiTexts.enterDate}
                    className={Css.formElement}
                    minDate={minDueDate}
                    element={DatePickerInput} />
                </div>
              </div>
            </div>
            {!businessUser && (
              <div className={Css.formRow}>
                <FormElement
                  name="repeatableData"
                  startDate={startDate}
                  element={TaskRepeatOptions} />
              </div>
            )}
          </div>
          <div className={Css.colTablet}>
            <FormLabel>{uiTexts.attachments}</FormLabel>
            {!!attachments.length && (
              <Attachments
                showAll
                disabled={disabled}
                useConfirm={false}
                attachments={attachments}
                onDelete={handleDeleteAttachment} />
            )}
            <div className={Css.formRow}>
              <CustomDropArea
                disabled={disabled}
                onChange={handleFilesChange} />
            </div>
          </div>
        </div>
      </SideBarContent>
      <SideBarFooter className={Css.footer}>
        <Button
          large block primary
          disabled={disabled || disableSubmit}
          onClick={onSubmit}>
          {disabled ? <Icons.Spinner data-wait /> : <Icons.Plus />}
          <span>{uiTexts.create}</span>
        </Button>
        <Button
          large block outline
          disabled={disabled}
          onClick={handleCloseClick}>
          <span>{uiTexts.cancel}</span>
        </Button>
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(FormContent);
