import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";

export const checkEventsFetching = ({ events: { fetchingData } }) => fetchingData;

export const getEventsData = createSelector(
  [
    ({ events: { data } }) => data,
    getActiveUsersData
  ],
  (data, users) => {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => ([
        key,
        value.map(({ user, document, updates, ...restData }) => {
          return {
            ...restData,
            document,
            action: document.lastUpdateAction || undefined,
            updates: updates || {},
            user: users.find(({ id }) => id === user)
              || (document.sourceData && document.sourceData.from ? { email: document.sourceData.from } : null)
          };
        })
      ]))
    );
  }
);

export const getDocumentEventsData = createSelector(getEventsData, ({ documents = [] }) => documents);
