import Css from "./style.module.scss";

import { checkIntegrationsFetching } from "selectors/integrations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import React, { useCallback } from "react";
import Step from "../Step";

const {
  QBWC_DEVELOPER_PORTAL_URL,
  QBWC_INSTALLATION_GUIDE_PATH
} = Constants;

const AddQuickBooksDesktopStep = ({ index }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const [
    quickBooksWebConnectorDescriptionA,
    quickBooksWebConnectorDescriptionB,
    quickBooksWebConnectorDescriptionC
  ] = messages.quickBooksWebConnectorDescription;

  const qwbcGuideUrl = `${QBWC_DEVELOPER_PORTAL_URL}${QBWC_INSTALLATION_GUIDE_PATH}`;

  const fetchingData = useSelector(checkIntegrationsFetching);

  const handleDownloadButtonClick = useCallback(() => {
    dispatch(IntegrationsActions.downloadQbwcFile());
  }, [dispatch]);

  return (
    <Step active number={index + 1}>
      <div className={Css.addQuickBooksDesktopBlock}>
        <div className={Css.text}>
          <div className={Css.title}>{IntegrationServices.QUICK_BOOKS_DESKTOP.label}</div>
          <p>{`${quickBooksWebConnectorDescriptionA}.`}</p>
          <p>{`${quickBooksWebConnectorDescriptionB}.`}</p>
          <p>
            <b>
              <span>{`${quickBooksWebConnectorDescriptionC} `}</span>
              <a href={qwbcGuideUrl} target="_blank">{uiTexts.here.toLowerCase()}</a>
            </b>
          </p>
        </div>
        <div className={Css.buttonContainer}>
          <Button success large loading={fetchingData} onClick={handleDownloadButtonClick}>
            {uiTexts.downloadYourQbwcProfile}
          </Button>
        </div>
      </div>
    </Step>
  );
};

export default React.memo(AddQuickBooksDesktopStep);
