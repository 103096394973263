import Css from "./style.module.scss";

import coloredLogo from "assets/coloredLogo.svg";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser, getUserData } from "selectors/user";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BusinessSelector from "nlib/common/BusinessSelector";
import BusinessesActions from "actions/BusinessesActions";
import Countries from "const/Countries";
import LanguageSelector from "nlib/common/LanguageSelector";
import Notifications from "nlib/common/Notifications";
import React, { useCallback, useMemo } from "react";
import SettingsDropDown from "nlib/common/SettingsDropDown";
import ShowIntroButton from "nlib/common/ShowIntroButton";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const Header = ({ onboarding, hideIntroButton }) => {
  const dispatch = useDispatch();

  const { businessId } = useParams();

  const activeOrganization = useSelector(getActiveOrganization) || {};

  const { holdUser } = useSelector(getUserData);

  const projectName = useSelector(getProjectName);

  const businessUser = useSelector(checkIsBusinessUser);

  const { superAdminAccess, countryCode, whiteLabel: { logoUrl } = {} } = activeOrganization || {};

  const handleBusinessSettingClick = useCallback(async() => {
    const result = await dispatch(UiActions.showEditBusinessSettingsWindow({ businessId }));

    if (result) {
      dispatch(BusinessesActions.editBusinessSettings(businessId, result));
    }
  }, [dispatch, businessId]);

  const logoStyle = useMemo(() => {
    return { backgroundImage: `url(${logoUrl || coloredLogo})` };
  }, [logoUrl]);

  return (
    <div className={classNames(Css.header, superAdminAccess && Css.superAdminAccess)}>
      {((activeOrganization.billingId && !activeOrganization.subscriptionId) || holdUser || !businessId || onboarding) && (
        <div className={Css.logo} title={projectName} style={logoStyle}>
          <img src={logoUrl || coloredLogo} />
        </div>
      )}
      {businessId
        ? (
          <>
            <BusinessSelector className={Css.businessSelector} />
            {!onboarding && !businessUser && (
              <div className={Css.settingsIcon} onClick={handleBusinessSettingClick}>
                <Icons.Gear />
              </div>
            )}
          </>
        )
        : null}
      <div className={Css.aside}>
        <div className={Css.item}><Notifications /></div>
        <div className={Css.item}><SettingsDropDown /></div>
        <div className={Css.item}><LanguageSelector /></div>
        {!hideIntroButton && businessUser && (countryCode !== Countries.CZ) && (
          <div className={Css.item}><ShowIntroButton /></div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Header);
