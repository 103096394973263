import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkIsBusinessUser } from "selectors/user";
import { getAccountsData } from "selectors/accounts";
import { getActiveOrganization } from "selectors/organizations";
import { getBanksData } from "selectors/metaData";
import { getContactsData } from "selectors/contacts";
import { getSelectedBusinessData, getSelectedBusinessProjects } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import DocumentsStatuses from "nlib/pages/DocumentsPage/DocumentsStatuses";
import IntegrationServices from "const/IntegrationServices";
import React, { Fragment, useCallback, useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";

const { highlightText, positiveText, negativeText } = CommonCss;

const {
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL },
  DOCUMENT_TYPES: { INVOICE, CREDIT_NOTE, RECEIPT, SALES_RECEIPT, BILL }
} = DataConstants;

const UPDATE_FIELDS = {
  STATUS: "status",
  PAYMENT_TYPE: "paymentType",
  TYPE: "type",
  LAST_EXPORTED_TO: "lastExportedTo",
  TRANSACTION_IDS: "transactionIds",
  LINKED_DOCUMENTS_IDS: "linkedDocumentsIds",
  DUPLICATED_DOCUMENTS_IDS: "duplicatedDocumentsIds",
  ORIGINAL_DOCUMENT_ID: "originalDocumentId",
  AMOUNT: "amount",
  AMOUNT_VAT: "amountVat",
  AMOUNT_CORRECTION: "amountCorrection",
  AMOUNT_VAT_RATES: "amountVatRates",
  CURRENCY: "currency",
  ISSUE_DATE: "issueDate",
  DUE_DATE: "dueDate",
  TAX_DATE: "taxDate",
  VENDOR_ID: "vendorId",
  BANK_ACCOUNT: "payTo.bankAccount",
  BANK_CODE: "payTo.bankCode",
  PAYMENT_ACCOUNT: "payTo.serviceAccountId",
  PAYMENT_DATE: "paymentDate",
  LOCATION: "location.name",
  LINE_ITEMS: "lineItems",
  DESCRIPTION: "description",
  TAGS: "tags",
  VARIABLE_SYMBOL: "extra.VS",
  CONSTANT_SYMBOL: "extra.KS",
  SPECIFIC_SYMBOL: "extra.SS",
  DOCUMENT_TYPE: "documentType"
};

const FIELD_CHANGE_TYPES = {
  SET: "set",
  CHANGE: "change",
  UNSET: "unset"
};

const LINE_ITEMS_MAX_COUNT = 100;

const {
  STATUS,
  LAST_EXPORTED_TO,
  TRANSACTION_IDS,
  LINKED_DOCUMENTS_IDS,
  DUPLICATED_DOCUMENTS_IDS,
  ORIGINAL_DOCUMENT_ID,
  AMOUNT,
  AMOUNT_VAT,
  CURRENCY,
  ISSUE_DATE,
  DUE_DATE,
  TAX_DATE,
  VENDOR_ID,
  BANK_ACCOUNT,
  BANK_CODE,
  PAYMENT_ACCOUNT,
  PAYMENT_DATE,
  LOCATION,
  LINE_ITEMS,
  DESCRIPTION,
  TAGS,
  VARIABLE_SYMBOL,
  CONSTANT_SYMBOL,
  SPECIFIC_SYMBOL,
  DOCUMENT_TYPE
} = UPDATE_FIELDS;

const { SET, CHANGE, UNSET } = FIELD_CHANGE_TYPES;

const DOCUMENT_TYPES = {
  [BUY]: { [INVOICE]: BILL, [RECEIPT]: RECEIPT, [CREDIT_NOTE]: CREDIT_NOTE },
  [SELL]: { [INVOICE]: INVOICE, [SALES_RECEIPT]: SALES_RECEIPT, [CREDIT_NOTE]: CREDIT_NOTE }
};

const ChangedFieldsList = (props) => {
  const { currentRevDocument } = props;

  const { uiTexts } = useSelector(getTextsData);

  const activeOrganization = useSelector(getActiveOrganization);

  const contactsData = useSelector(getContactsData);

  const banksData = useSelector(getBanksData);

  const accountsData = useSelector(getAccountsData);

  const businessUser = useSelector(checkIsBusinessUser);

  const projects = useSelector(getSelectedBusinessProjects);

  const czCountry = activeOrganization.countryCode === Countries.CZ;

  const [updates, prevRevDocument] = useMemo(() => {
    const nextState = props.updates || {};

    const prevState = props.prevRevDocument || {};

    if (nextState.type || nextState.paymentType) {
      const paymentType = nextState.paymentType || prevState.paymentType;

      const type = nextState.type || prevState.type;

      return [
        {
          ...nextState,
          documentType: DOCUMENT_TYPES?.[paymentType]?.[type]
        },
        {
          ...prevState,
          documentType: DOCUMENT_TYPES?.[prevState.paymentType]?.[prevState.type]
        }
      ];
    }

    return [nextState, prevState];
  }, [props.updates, props.prevRevDocument]);

  const {
    id: selectedBusinessId,
    extraData: { xeroClassification, zohoClassification } = {}
  } = useSelector(getSelectedBusinessData);

  const categories = useMemo(() => {
    return (xeroClassification || zohoClassification || []).filter(({ paymentsAllowed }) => paymentsAllowed);
  }, [xeroClassification, zohoClassification]);

  const normalizeValue = useCallback((value) => {
    if (typeof value === "string") value = value.trim();

    const valueCheck = value !== "" && value !== null && value !== undefined;

    if (valueCheck) {
      const arrayCheck = !Array.isArray(value)
        || (value.length !== 0 && value.some((item) => item && (typeof item !== "object" || item.value || item.description)));

      if (arrayCheck) {
        const objectCheck = typeof value !== "object" || Object.values(value).length !== 0;

        if (objectCheck) return value;
      }
    }

    return null;
  }, []);

  const convertArray = useCallback((value) => value.map((item) => `${item}`.trim()).join(", "), []);

  const convertDate = useCallback((value) => moment.utc(value).format(Constants.DATETIME_FORMATS.DATE_TEXT), []);

  const convertLinksList = useCallback((value, route) => {
    return value.map((text, index) => {
      text = `${text}`.trim();

      const query = Utils.objectToQueryString({ text });

      const linkUrl = `${window.origin}/${selectedBusinessId}${route}?${query}`;

      return (
        <Fragment key={text}>
          <a href={linkUrl} target="_blank">{text}</a>
          {index === value.length - 1 ? "" : ", "}
        </Fragment>
      );
    });
  }, [selectedBusinessId]);

  const fieldParameters = useMemo(
    () => ({
      [STATUS]: {
        label: uiTexts.status,
        convertor: (value) => uiTexts[
          DocumentsStatuses.getStatusData(value, activeOrganization.countryCode)?.labelLangId
        ] || value
      },
      [LAST_EXPORTED_TO]: {
        label: uiTexts.exportedTo,
        convertor: (value) => IntegrationServices.getByValue(value)?.label || value
      },
      [TRANSACTION_IDS]: {
        label: uiTexts.transactions,
        convertor: (value) => convertLinksList(value, UiRoutes.TRANSACTIONS)
      },
      [LINKED_DOCUMENTS_IDS]: {
        label: uiTexts.linked,
        convertor: (value) => convertLinksList(value, UiRoutes.DOCUMENTS)
      },
      [DUPLICATED_DOCUMENTS_IDS]: {
        label: uiTexts.duplicates,
        convertor: (value) => convertLinksList(value, UiRoutes.DOCUMENTS)
      },
      [DOCUMENT_TYPE]: {
        label: uiTexts.type,
        convertor: (value) => {
          return uiTexts[value];
        }
      },
      [ORIGINAL_DOCUMENT_ID]: {
        label: `${uiTexts.document} #`
      },
      [AMOUNT]: {
        label: uiTexts.total,
        convertor: Utils.toMoneyString
      },
      [AMOUNT_VAT]: {
        label: uiTexts.vat,
        convertor: Utils.toMoneyString
      },
      [CURRENCY]: {
        label: uiTexts.currency
      },
      [ISSUE_DATE]: {
        label: uiTexts.issueDate,
        convertor: convertDate
      },
      [DUE_DATE]: {
        label: uiTexts.dueDate,
        convertor: convertDate
      },
      [TAX_DATE]: {
        label: uiTexts.taxDate,
        convertor: convertDate
      },
      [VENDOR_ID]: {
        label: uiTexts.contact,
        convertor: (value) => {
          const list = [(contactsData.find(({ id }) => id === value) || {}).name || value];

          return businessUser || Utils.checkIsTouchDevice()
            ? list.join(", ")
            : convertLinksList(list, UiRoutes.CONTACTS);
        }
      },
      [BANK_ACCOUNT]: {
        label: uiTexts.bankAccount
      },
      [BANK_CODE]: {
        label: uiTexts.bank,
        convertor: (value) => (banksData.find(({ code }) => code === value) || {}).name || value
      },
      ...(czCountry
        ? {
          [DESCRIPTION]: {
            label: uiTexts.description
          },
          [VARIABLE_SYMBOL]: {
            label: "VS"
          },
          [CONSTANT_SYMBOL]: {
            label: "KS"
          },
          [SPECIFIC_SYMBOL]: {
            label: "SS"
          },
          [TAGS]: {
            label: uiTexts.tags,
            convertor: convertArray
          }
        }
        : {
          [PAYMENT_ACCOUNT]: {
            label: uiTexts.paid,
            convertor: (value) => {
              const { paymentType } = currentRevDocument;

              const prefix = paymentType ? (`${paymentType === BUY ? uiTexts.from : uiTexts.to} `).toLowerCase() : "";

              const accountData = value && (
                accountsData.find(({ extraData = {} }) => {
                  const { xeroAccountId, zohoAccountId, quickBooksAccountId } = extraData;

                  return (xeroAccountId || zohoAccountId || quickBooksAccountId) === value;
                }) || categories.find(({ accountId }) => accountId === value)
              );

              return accountData ? `${prefix}${accountData.displayName || accountData.name}` : uiTexts.no;
            }
          },
          [PAYMENT_DATE]: {
            label: uiTexts.paymentDate,
            convertor: convertDate
          },
          [LOCATION]: {
            label: uiTexts.location
          },
          ...(Object.fromEntries(new Array(LINE_ITEMS_MAX_COUNT).fill().map((value, index) => ([
            `${LINE_ITEMS}.${index}`,
            {
              label: `${uiTexts.item} #${index + 1}`,
              convertor: ({
                description,
                quantity,
                amountBase,
                vatRate,
                category: { name: categoryName } = {},
                item: { name: itemName } = {},
                class: { name: className } = {},
                project: { name: projectName } = {}
              }) => {
                quantity = quantity ? ` [x${quantity}]` : "";
                amountBase = `${uiTexts.subtotal}: ${Utils.toMoneyString(+amountBase || 0)}`;
                vatRate = `${uiTexts.vat}: ${vatRate || 0}%`;
                categoryName = categoryName ? `${uiTexts.category}: ${categoryName}` : "";
                itemName = itemName ? `${uiTexts.item}: ${itemName}` : "";
                className = className ? `${uiTexts.class}: ${className}` : "";
                projectName = projectName ? `${
                  [
                    projects.some(({ customer }) => !customer) && uiTexts.project,
                    projects.some(({ customer }) => customer) && uiTexts.customer
                  ].filter(Boolean).join("/")
                }: ${projectName}` : "";

                const extraParametes = [
                  amountBase,
                  vatRate,
                  categoryName,
                  itemName,
                  className,
                  projectName
                ].filter(Boolean).join(", ");

                return `${description}${quantity} (${extraParametes})`;
              }
            }
          ]))))
        })
    }),
    [
      businessUser,
      uiTexts,
      activeOrganization,
      categories,
      contactsData,
      banksData,
      accountsData,
      projects,
      czCountry,
      currentRevDocument,
      convertArray,
      convertDate,
      convertLinksList
    ]
  );

  const fieldChangeTypeProperties = useMemo(() => ({
    [SET]: {
      icon: Icons.Plus,
      iconClassName: positiveText
    },
    [CHANGE]: {
      icon: Icons.GitCommit,
      iconClassName: highlightText
    },
    [UNSET]: {
      icon: Icons.X,
      iconClassName: negativeText
    }
  }), []);

  const fieldsList = useMemo(() => {
    return Object.entries(fieldParameters)
      .map(([fieldKey, { label: fieldLabel, convertor: fieldValueConvertor = (value) => `${value}`.trim() }]) => {
        const rawFieldValue = (updates || {})[fieldKey] || Utils.getPropertyByPath(updates, fieldKey);

        const rawPrevFieldValue = (prevRevDocument || {})[fieldKey] || Utils.getPropertyByPath(prevRevDocument, fieldKey);

        let fieldValue = normalizeValue(rawFieldValue);

        let prevFieldValue = normalizeValue(rawPrevFieldValue);

        const fieldChangeType = prevFieldValue === null ? SET : (fieldValue === null ? UNSET : CHANGE);

        const valueChanged = !Utils.checkDeepEquality({ value: fieldValue }, { value: rawPrevFieldValue });

        prevFieldValue = prevFieldValue && fieldValueConvertor(prevFieldValue);
        fieldValue = fieldValue && fieldValueConvertor(fieldValue);

        if (fieldChangeType === SET ? fieldValue !== null : (rawFieldValue !== undefined && valueChanged)) {
          const { icon: Icon, iconClassName } = fieldChangeTypeProperties[fieldChangeType];

          const title = (() => {
            const valueA = typeof prevFieldValue === "object" ? rawPrevFieldValue : prevFieldValue;

            const valueB = typeof fieldValue === "object" ? rawFieldValue : fieldValue;

            return prevFieldValue === null ? valueB : `${valueA}${fieldValue === null ? "" : ` => ${valueB}`}`;
          })();

          return (
            <div className={Css.listItem} key={fieldKey}>
              <Icon className={iconClassName} />
              <span title={title}>
                <span className={Css.fieldName}>{`${fieldLabel}: `}</span>
                {fieldChangeType !== SET && <span
                  className={classNames(Css.oldValue, fieldChangeType === UNSET && Css.deletedValue)}>
                  {prevFieldValue}
                </span>}
                {fieldChangeType === CHANGE && <Icons.ArrowRight className={highlightText} />}
                {fieldChangeType !== UNSET && <span>{fieldValue}</span>}
              </span>
            </div>
          );
        }

        return null;
      }).filter(Boolean);
  }, [updates, prevRevDocument, fieldParameters, fieldChangeTypeProperties, normalizeValue]);

  if (!fieldsList.length) return null;

  return (
    <div className={Css.changedFieldsList}>
      {fieldsList}
    </div>
  );
};

export default React.memo(ChangedFieldsList);
