import { checkIsBusinessUser, getUserData } from "selectors/user";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";

const { DATE_TEXT } = Constants.DATETIME_FORMATS;

const { COMPLETED } = DataConstants.TASK_STATUSES;

const useTaskContent = (content = {}) => {
  const { status, startDate, dueDate, createdBy, createdAt, targetUser } = content;

  const { uiTexts } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const businessUser = useSelector(checkIsBusinessUser);

  const { id: userId } = useSelector(getUserData);

  const taskCreatedByYou = createdBy && userData.id === createdBy.id;

  const selectedBusinessUsersData = useSelector(getSelectedBusinessUsersData);

  const createdByUserData = Utils.arrayFindById(selectedBusinessUsersData, (createdBy && createdBy.id) || createdBy);

  const taskCreatedByYourSide = createdByUserData
    && UserRoles.getRoleType(userData.role) === UserRoles.getRoleType(createdByUserData.role);

  const taskForContent = useMemo(() => {
    if (targetUser) {
      if (targetUser.id === userId) return uiTexts.forYou;

      const { fullName: userFullName } = targetUser;

      return Utils.replaceTextVars(uiTexts.forUserName, { userName: userFullName });
    }

    if ((taskCreatedByYourSide && businessUser) || (!taskCreatedByYourSide && !businessUser)) {
      return uiTexts.forAccountant;
    }

    return uiTexts.forClient;
  }, [targetUser, taskCreatedByYourSide, businessUser, uiTexts, userId]);

  const createdByContent = createdBy ? (taskCreatedByYou ? uiTexts.you : createdBy.fullName) : uiTexts.unknown;

  const today = moment.utc(Utils.formatNoTimeZoneDate(new Date(), false));

  return {
    completed: status === COMPLETED,
    dueDateContent: status === COMPLETED ? uiTexts.completed.toUpperCase() : (dueDate && moment(dueDate).format(DATE_TEXT)),
    dueDateStatus: (status !== COMPLETED) && dueDate && (
      moment(dueDate).isSameOrBefore(today.startOf("day")) ? "negative"
        : (moment(dueDate).isAfter(today.add(1, "day").endOf("day")) ? null : "warning")
    ),
    taskForContent,
    createdAtContent: moment.duration(moment(createdAt).diff(moment())).humanize(true),
    startDateContent: startDate && moment(startDate).format(DATE_TEXT),
    createdByContent
  };
};

export default useTaskContent;
