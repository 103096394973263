import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Input from "nlib/ui/Input";
import React, { useCallback, useState } from "react";
import Step from "../Step";

const BusinessDescriptionStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");

  const { messages, uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const [fetching, setFetching] = useState(false);

  const handleButtonClick = useCallback(async() => {
    setFetching(true);

    await dispatch(
      BusinessesActions.editBusinessSettings(selectedBusinessId, { businessDescription: text.trim() }, true)
    );

    setFetching(false);
  }, [text, selectedBusinessId, dispatch]);

  const active = index === currentStep;

  return (
    <>
      <Step
        number={index + 1}
        className={ParentCss.stepThird}
        active={active}
        success={currentStep > index}>
        <div className={ParentCss.row}>
          <div className={ParentCss.col}>
            <div className={ParentCss.subtitle}>
              {uiTexts.describeYourBusinessForAi}
            </div>
            {active && (
              <>
                <p className={ParentCss.text}>
                  {messages.onboardingDescribeBusiness}
                </p>
                <div className={ParentCss.row}>
                  <div className={ParentCss.col}>
                    <Input
                      value={text}
                      disabled={fetching}
                      placeholder={messages.enterBusinessDescription}
                      onChange={setText} />
                  </div>
                  <Button
                    primary large
                    disabled={fetching}
                    className={ParentCss.likeButton}
                    onClick={handleButtonClick}>
                    {text.trim() ? uiTexts.done : uiTexts.skip}
                  </Button>
                </div>
              </>
            )}
          </div>
          {currentStep > index && (
            <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
              <Icons.Check weight="bold" />
              <span>{uiTexts.completed}</span>
            </div>
          )}
        </div>
      </Step>
    </>
  );
};

export default React.memo(BusinessDescriptionStep);
