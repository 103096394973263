import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";
import moment from "moment";

const {
  DATETIME_FORMATS: { DATE_TEXT }
} = Constants;

const { TASK_REPEAT_END_TYPES } = DataConstants;

const Explanation = (props) => {
  const {
    className,
    startDate,
    periodValue,
    periodType,
    endType,
    endAfterDate,
    endAfterRepetitions,
    occurrencesRemaining,
    duePeriodType,
    duePeriodValue
  } = props;

  const { messages } = useSelector(getTextsData);

  if (!periodValue || !periodType || !startDate) return null;

  const nextTaskStartDate = moment.utc(startDate).add(periodValue, periodType);

  const [textA, textB, textC, textD] = messages.taskRepetitionExplanation;

  return (
    <div className={className}>
      {[
        (endType === TASK_REPEAT_END_TYPES.DATE && endAfterDate && nextTaskStartDate.isSameOrAfter(moment(endAfterDate)))
          ? Utils.replaceTextVars(textA, { endAfterDate: moment(endAfterDate).format(DATE_TEXT) })
          : Utils.replaceTextVars(textB, { nextTaskStartDate: nextTaskStartDate.format(DATE_TEXT) }),

        !!duePeriodType && !!duePeriodValue && Utils.replaceTextVars(textC, {
          dueOnDate: moment(nextTaskStartDate).add(duePeriodValue, duePeriodType).format(DATE_TEXT)
        }),
        endType === TASK_REPEAT_END_TYPES.OCCURRENCES && Utils.replaceTextVars(textD, {
          occurrencesRemaining: occurrencesRemaining || endAfterRepetitions || 0
        })
      ].filter(Boolean).join(" ")}
    </div>
  );
};

export default React.memo(Explanation);
