import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import React, { useMemo } from "react";
import classNames from "classnames";

const ICONS = {
  pdf: Icons.FilePdf,
  xls: Icons.FileXls,
  xlsx: Icons.FileXls,
  doc: Icons.FileDoc,
  docx: Icons.FileDoc,
  jpg: Icons.FileJpg,
  jpeg: Icons.FileJpg,
  png: Icons.FilePng,
  gif: Icons.FileImage,
  avi: Icons.FileVideo,
  mp4: Icons.FileVideo,
  mpeg: Icons.FileVideo,
  csv: Icons.FileCsv,
  html: Icons.FileHtml,
  ppt: Icons.FilePpt,
  zip: Icons.FileZip
};

const FileTypedIcon = ({ className, fileName = "", ...rest }) => {
  const extension = useMemo(() => fileName.split(".").pop(), [fileName]);

  const Icon = ICONS[extension] || Icons.File;

  return (
    <div
      {...rest}
      className={classNames(Css.fileTypedIcon, className)}
      data-extension={extension}>
      <Icon />
    </div>
  );
};

export default React.memo(FileTypedIcon);
