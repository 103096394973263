import { useDispatch } from "react-redux";
import DataConstants from "const/DataConstants";
import Form from "nlib/common/Form";
import FormContent from "./lib/FormContent";
import React, { useCallback, useMemo, useState } from "react";
import TasksActions from "actions/TasksActions";
import useEnvVars from "hooks/useEnvVars";

const { TASK_STATUSES } = DataConstants;

const validation = ({ text }) => {
  return text.trim() ? null : { text: "required" };
};

const TaskForm = ({ className }) => {
  const [{ editTask }, setEnvVars] = useEnvVars();

  const dispatch = useDispatch();

  const [attachments, setAttachments] = useState([]);

  const [fetching, setFetching] = useState(false);

  const [, taskStatus = TASK_STATUSES.TO_DO] = (editTask || "").split(".");

  const initialValues = useMemo(() => {
    return {
      status: taskStatus,
      text: "",
      dueDate: "",
      targetUser: ""
    };
  }, [taskStatus]);

  const handleFormSubmit = useCallback(async({ values }) => {
    const { status, text, targetUser, dueDate, startDate, repeatableData } = values;

    setFetching(true);

    setEnvVars({ fromDate: null, toDate: null, targetUser: null });

    const files = attachments.map(({ file }) => file);

    const result = await dispatch(TasksActions.addNewTask({
      status,
      text,
      targetUser,
      startDate,
      dueDate,
      files,
      ...(repeatableData?.enabled ? { repeatableData } : {})
    }));

    if (result) {
      dispatch(TasksActions.fetchTasksList(false, false));
      setEnvVars({ editTask: null });
    } else {
      setFetching(false);
    }
  }, [dispatch, attachments, setEnvVars]);

  const handleCancel = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  return (
    <Form
      key={taskStatus}
      component={null}
      initialValues={initialValues}
      validation={validation}
      onSubmit={handleFormSubmit}>
      {({ values, hasChanges, onSubmit }) => {
        return (
          <FormContent
            className={className}
            disabled={fetching}
            values={values}
            attachments={attachments}
            setAttachments={setAttachments}
            hasChanges={hasChanges}
            onSubmit={onSubmit}
            onCancel={handleCancel} />
        );
      }}
    </Form>
  );
};

export default React.memo(TaskForm);
