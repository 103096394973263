import Css from "./style.module.scss";

import { getLanguage, getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import React, { useCallback, useMemo } from "react";
import Select from "nlib/ui/Select";
import TextsActions from "actions/TextsActions";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import classNames from "classnames";

const LanguageSelector = ({ disabled, className, dropDownClassName }) => {
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);

  const { uiTexts } = useSelector(getTextsData);

  const options = useMemo(() => Constants.LANGUAGES_ARRAY.map(({ code }) => ({
    value: code,
    label: code.toUpperCase()
  })), []);

  const handleChange = useCallback(async(code) => {
    dispatch(UiActions.togglePreloader(true));
    await dispatch(TextsActions.setAppLanguage(code));
    dispatch(UiActions.togglePreloader(false));
  }, [dispatch]);

  return (
    <Select
      alignRight
      className={classNames(Css.languageSelector, className)}
      placeholder={uiTexts.selectLanguage}
      dropDownClassName={classNames(Css.dropDown, dropDownClassName)}
      disabled={disabled}
      value={language}
      options={options}
      useCaret={Utils.checkIsTouchDevice()}
      buttonClassName={Css.selectButton}
      onChange={handleChange} />
  );
};

export default React.memo(LanguageSelector);
