import * as Icons from "@phosphor-icons/react";
import { AutoCompleteInput } from "nlib/ui";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useState } from "react";
import UserRoles from "const/UserRoles";

const SelectUserAutocomplete = ({ value, allowedRoles, onChange, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const usersGroups = useMemo(() => {
    return [
      { value: 0, label: uiTexts.accountants },
      { value: 1, label: uiTexts.clients }
    ];
  }, [uiTexts]);

  const usersOptions = useMemo(() => {
    return usersData
      .filter(({ role }) => !allowedRoles || allowedRoles.includes(role))
      .map(({ id, email, fullName, role }) => {
        return {
          group: UserRoles.checkIsAccountant(role) ? 0 : 1,
          value: id,
          label: email === fullName ? fullName : `${fullName} (${email})`
        };
      });
  }, [usersData, allowedRoles]);

  const [userName, setUserName] = useState(usersData.find((userData) => userData.id === value?.id)?.fullName || "");

  const handleChange = useCallback((val) => {
    setUserName(val);
    onChange(null);
  }, [onChange]);

  const handleAutoComplete = useCallback((val) => {
    setUserName(val.label);
    onChange(val.value);
  }, [onChange]);

  return (
    <AutoCompleteInput
      iconBefore={Icons.User}
      placeholder={uiTexts.selectUser}
      groups={usersGroups}
      items={usersOptions}
      value={userName}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete}
      {...restProps} />
  );
};

export default SelectUserAutocomplete;
