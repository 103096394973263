import Css from "./style.module.scss";

import React from "react";
import classNames from "classnames";

const SideBar = ({ className, children, ...restProps }) => {
  return (
    <div className={classNames(Css.sideBar, className)} {...restProps}>
      {children}
    </div>
  );
};

export { default as SideBarHeader } from "./lib/SideBarHeader";
export { default as SideBarContent } from "./lib/SideBarContent";
export { default as SideBarFooter } from "./lib/SideBarFooter";

export default React.memo(SideBar);
