import * as Icons from "@phosphor-icons/react";
import { memoize } from "decko";
import DataConstants from "const/DataConstants";

const { TO_EXTRACT, TO_REPORT, TO_REVIEW, EXCLUDED, EXPORTED } = DataConstants.STATUSES;

export default class DocumentsStatuses {
  static STATUSES_DATA = {
    [TO_EXTRACT]: {
      value: TO_EXTRACT,
      iconComponent: Icons.Cloud,
      errorIconComponent: Icons.CloudSlash,
      labelLangId: "processing",
      descriptionMessageId: "toExtractDocumentsDescription"
    },
    [TO_REVIEW]: {
      value: TO_REVIEW,
      canBeAssigned: true,
      iconComponent: Icons.Eye,
      labelLangId: "toReview",
      descriptionMessageId: "toReviewDocumentsDescription"
    },
    [EXCLUDED]: {
      value: EXCLUDED,
      canBeAssigned: true,
      iconComponent: Icons.MinusCircle,
      labelLangId: "excluded",
      descriptionMessageId: "excludedDocumentsDescription"
    },
    [TO_REPORT]: {
      value: TO_REPORT,
      canBeAssigned: true,
      iconComponent: Icons.Upload,
      labelLangId: "exporting",
      descriptionMessageId: "exportingDocumentsDescription"
    },
    [EXPORTED]: {
      value: EXPORTED,
      iconComponent: Icons.ArrowSquareOut,
      labelLangId: "exported",
      descriptionMessageId: "exportedDocumentsDescription"
    },
    [null]: {
      value: null,
      iconComponent: Icons.ListDashes,
      labelLangId: "all",
      descriptionMessageId: "allDocumentsDescription"
    }
  };

  static BUSINESS_USER_STATUSES_DATA = {
    [TO_EXTRACT]: {
      value: TO_EXTRACT,
      iconComponent: Icons.Cloud,
      errorIconComponent: Icons.CloudSlash,
      labelLangId: "processing",
      descriptionMessageId: "toExtractDocumentsDescription"
    },
    [TO_REVIEW]: {
      value: TO_REVIEW,
      canBeAssigned: true,
      iconComponent: Icons.Eye,
      labelLangId: "underReview",
      descriptionMessageId: "toReviewDocumentsDescription"
    },
    [EXPORTED]: {
      value: EXPORTED,
      iconComponent: Icons.ArrowSquareOut,
      labelLangId: "exported",
      descriptionMessageId: "exportedDocumentsDescription"
    },
    [null]: {
      value: null,
      iconComponent: Icons.ListDashes,
      labelLangId: "all",
      descriptionMessageId: "allDocumentsDescription"
    }
  };

  @memoize
  static getStatusData(status) {
    return status === undefined ? DocumentsStatuses.STATUSES_DATA : DocumentsStatuses.STATUSES_DATA[status];
  }

  @memoize
  static getBusinessUserStatusData(status) {
    return status === undefined
      ? DocumentsStatuses.BUSINESS_USER_STATUSES_DATA
      : DocumentsStatuses.BUSINESS_USER_STATUSES_DATA[status];
  }
}
