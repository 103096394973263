import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React from "react";
import classNames from "classnames";

const SideBarHeader = ({ disabled, children, className, titleClassName, onCloseClick, ...restProps }) => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <div className={classNames(Css.sideBarHeader, className)} {...restProps}>
      <div className={Css.content}>
        <div className={classNames(Css.title, titleClassName)}>{children}</div>
        <Button
          large outline
          disabled={disabled}
          icon={Icons.X}
          onClick={onCloseClick}>
          {uiTexts.close}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SideBarHeader);
