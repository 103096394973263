import ParentCss from "../../style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import * as Yup from "yup";
import { Tooltip } from "nlib/ui";
import { getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import BusinessesActions from "actions/BusinessesActions";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Input from "nlib/ui/Input";
import OrganizationsActions from "actions/OrganizationsActions";
import React, { useCallback, useMemo, useState } from "react";
import Step from "../Step";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";

const WHO_ASKED = {
  ME: "me",
  ANOTHER: "another"
};

const USER_EMAIL_VALIDATION_SCHEMA = Yup.string().trim().email().required();

const WhoAskedStep = ({ index, currentStep }) => {
  const dispatch = useDispatch();

  const { messages, uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId } = useSelector(getSelectedBusinessData);

  const [fetching, setFetching] = useState(false);

  const [whoAsked, setWhoAsked] = useState(null);

  const [email, setEmail] = useState("");

  const usersData = useSelector(getAllUsersData);

  const accountantUsersData = useMemo(() => {
    return usersData.filter(({ role }) => {
      return UserRoles.checkIsAccountant(role);
    });
  }, [usersData]);

  const accountantUserEmail = useMemo(() => {
    return accountantUsersData.some(({ email: userEmail }) => {
      return userEmail && email && userEmail.trim().toLowerCase() === email.trim().toLowerCase();
    });
  }, [email, accountantUsersData]);

  const validEmail = useMemo(() => {
    return USER_EMAIL_VALIDATION_SCHEMA.isValidSync(email) && !accountantUserEmail;
  }, [email, accountantUserEmail]);

  const handleWhoAskedButtonClick = useCallback(async({ currentTarget: { dataset: { value } } }) => {
    if (value === WHO_ASKED.ANOTHER) {
      setWhoAsked(value);
    } else {
      setFetching(true);
      await dispatch(
        BusinessesActions.editBusinessSettings(selectedBusinessId, { rpaAutoAskClient: false }, true)
      );
      setFetching(false);
    }
  }, [dispatch, selectedBusinessId]);

  const handleEmailChange = useCallback((value) => {
    setEmail(value);
  }, []);

  const handleContinueButtonClick = useCallback(async() => {
    setFetching(true);

    const rpaAutoAskClient = whoAsked === WHO_ASKED.ANOTHER;

    const rpaClientEmail = rpaAutoAskClient ? email.trim().toLowerCase() : "";

    if (rpaAutoAskClient) {
      const savedEmailLsKey = `${Constants.LS_KEYS.LAST_ASKED_BUSINESS_EMAIL}_${selectedBusinessId}`;

      const successful = await dispatch(OrganizationsActions.askBusinessUser([], rpaClientEmail, "", "", true));

      if (successful) {
        Utils.storageValue(savedEmailLsKey, `${rpaClientEmail}:${moment().unix()}`);
      }
    }

    await dispatch(
      BusinessesActions.editBusinessSettings(
        selectedBusinessId,
        { rpaAutoAskClient, rpaClientEmail },
        true
      )
    );

    dispatch(OrganizationsActions.fetchUsersList(true, true));

    setFetching(false);
  }, [dispatch, email, selectedBusinessId, whoAsked]);

  return (
    <Step
      number={index + 1}
      className={ParentCss.stepThird}
      active={currentStep === index}
      success={currentStep > index}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>
            {messages.onboardingWhoAsked}
          </div>
          {currentStep === index && (
            <>
              <div className={ParentCss.buttons}>
                <Button
                  outline large
                  primary={whoAsked === WHO_ASKED.ME}
                  loading={fetching}
                  data-value={WHO_ASKED.ME}
                  className={ParentCss.button}
                  onClick={handleWhoAskedButtonClick}>
                  <span>{uiTexts.me}</span>
                </Button>
                <Button
                  outline large
                  primary={whoAsked === WHO_ASKED.ANOTHER}
                  loading={fetching}
                  data-value={WHO_ASKED.ANOTHER}
                  className={ParentCss.button}
                  onClick={handleWhoAskedButtonClick}>
                  <span>{uiTexts.anotherPerson}</span>
                </Button>
              </div>
              {whoAsked && (
                <div className={Css.form}>
                  <div className={Css.col}>
                    {whoAsked === WHO_ASKED.ANOTHER && (
                      <div className={Css.inputWrap}>
                        <Input
                          type="email"
                          error={!validEmail}
                          invalid={!validEmail}
                          valid={validEmail}
                          disabled={fetching}
                          placeholder={messages.enterPersonsEmailWhoAsked}
                          value={email}
                          onChange={handleEmailChange} />
                        <Tooltip opened={accountantUserEmail}>
                          <div className={Css.accountantUserEmailError}>
                            <Icons.Warning />
                            <span>{messages.userIsAccountant}</span>
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className={Css.col}>
                    <Button
                      primary large block
                      loading={fetching}
                      disabled={whoAsked === WHO_ASKED.ANOTHER && !validEmail}
                      onClick={handleContinueButtonClick}>{uiTexts.continue}</Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {currentStep > index && (
          <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
            <Icons.Check weight="bold" />
            <span>{uiTexts.selected}</span>
          </div>
        )}
      </div>
    </Step>
  );
};

export default React.memo(WhoAskedStep);
